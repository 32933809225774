import React, { useState } from 'react';
import { FooterSC } from './styles'
import Logo from './logo'
export default () => {
    const [year, setYear] = useState(new Date().getFullYear())
    return <FooterSC>
        <div className="top">
            <Logo />
            <div className="links">
                <ul>
                    <li>Hizmetlerimiz</li>
                    <li>Web Tasarım</li>
                    <li>Web Barındırma</li>
                    <li>Yazılım Geliştirme</li>
                    <li>Ağ Yönetimi</li>
                    <li>Alan Adı Hizmetleri</li>
                </ul>
                <ul>
                    <li>Konular</li>
                    <li>Seo</li>
                    <li>UX/UI</li>
                </ul>
                <ul>
                    <li>Diğer</li>
                    <li>İş İlanları</li>
                    <li>Gizlilik Politikası</li>
                    <li>Kullanım Koşulları</li>
                </ul>
            </div>
        </div>
        <div className="bottom">
            Tüm hakları saklıdır. © {year}
        </div>
    </FooterSC>
}