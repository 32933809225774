import React, { Component } from 'react';
import Swiper from 'react-id-swiper';
import Host from '../../host.svg'
import {SliderSC} from './styles'
const params = {
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
    dynamicBullets: true
  },
  loop: true,
  autoplay: {
    delay: 8000,
    disableOnInteraction: false
  },
};

class Slider extends Component {
  render() {
    return (
        <SliderSC>
          <Swiper {...params}>
            <div>
              <div className="sol">
                <h1>
                  Şirketiniz için kolay ve hızlı teknoloji çözümleri
                </h1>
                <p>
                  Network kurulum ve geliştirme süreçlerinde uzman bir ekiple çalışma
                  kolaylığını deneyimleyin.
                </p>
                <p>
                  <img src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTIgNTEyIiB3aWR0aD0iMzJweCIgaGVpZ2h0PSIzMnB4Ij4KICA8Zz4KICAgIDxnIGZpbGw9IiMyMzFGMjAiPgogICAgICA8cGF0aCBkPSJtMzU0LjIsMjQ3LjRsLTEzNS4xLTkyLjRjLTQuMi0zLjEtMTUuNC0zLjEtMTYuMyw4LjZ2MTg0LjhjMSwxMS43IDEyLjQsMTEuOSAxNi4zLDguNmwxMzUuMS05Mi40YzMuNS0yLjEgOC4zLTEwLjcgMC0xNy4yem0tMTMwLjUsODEuM3YtMTQ1LjRsMTA2LjEsNzIuNy0xMDYuMSw3Mi43eiIgZmlsbD0iI2ZmMDAwMCIvPgogICAgICA8cGF0aCBkPSJNMjU2LDExQzEyMC45LDExLDExLDEyMC45LDExLDI1NnMxMDkuOSwyNDUsMjQ1LDI0NXMyNDUtMTA5LjksMjQ1LTI0NVMzOTEuMSwxMSwyNTYsMTF6IE0yNTYsNDgwLjEgICAgQzEzMi40LDQ4MC4xLDMxLjksMzc5LjYsMzEuOSwyNTZTMTMyLjQsMzEuOSwyNTYsMzEuOVM0ODAuMSwxMzIuNCw0ODAuMSwyNTZTMzc5LjYsNDgwLjEsMjU2LDQ4MC4xeiIgZmlsbD0iI2ZmMDAwMCIvPgogICAgPC9nPgogIDwvZz4KPC9zdmc+Cg==" alt="eren" />
                  Çalışma alanlarımız hakkında detaylı video.
                </p>
              </div>
              <div className="sag">
                <img src="https://cdn.dribbble.com/users/369527/screenshots/3813402/pietrasiak_laptop_isometric.png" alt="erozan" />
              </div>
            </div>
            <div>
              <div className="sol">
                <h1>
                Hızlı ve Güvenli Web Hosting
                </h1>
                <p>
                Uzmanlardan oluşan ekibimiz web sitenizin sorunsuz çalışması için bütün teknik sorunları çözecektir. Her zaman.
                </p>
                <p>
                  <img src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTIgNTEyIiB3aWR0aD0iMzJweCIgaGVpZ2h0PSIzMnB4Ij4KICA8Zz4KICAgIDxnIGZpbGw9IiMyMzFGMjAiPgogICAgICA8cGF0aCBkPSJtMzU0LjIsMjQ3LjRsLTEzNS4xLTkyLjRjLTQuMi0zLjEtMTUuNC0zLjEtMTYuMyw4LjZ2MTg0LjhjMSwxMS43IDEyLjQsMTEuOSAxNi4zLDguNmwxMzUuMS05Mi40YzMuNS0yLjEgOC4zLTEwLjcgMC0xNy4yem0tMTMwLjUsODEuM3YtMTQ1LjRsMTA2LjEsNzIuNy0xMDYuMSw3Mi43eiIgZmlsbD0iI2ZmMDAwMCIvPgogICAgICA8cGF0aCBkPSJNMjU2LDExQzEyMC45LDExLDExLDEyMC45LDExLDI1NnMxMDkuOSwyNDUsMjQ1LDI0NXMyNDUtMTA5LjksMjQ1LTI0NVMzOTEuMSwxMSwyNTYsMTF6IE0yNTYsNDgwLjEgICAgQzEzMi40LDQ4MC4xLDMxLjksMzc5LjYsMzEuOSwyNTZTMTMyLjQsMzEuOSwyNTYsMzEuOVM0ODAuMSwxMzIuNCw0ODAuMSwyNTZTMzc5LjYsNDgwLjEsMjU2LDQ4MC4xeiIgZmlsbD0iI2ZmMDAwMCIvPgogICAgPC9nPgogIDwvZz4KPC9zdmc+Cg==" alt="eren" />
                  Çalışma alanlarımız hakkında detaylı video.
                </p>
              </div>
              <div className="sag">
                <img src={Host} alt="erozan" />
              </div>
            </div>

          </Swiper>

        </SliderSC>
    );
  }
}

export default Slider;
