import React from 'react';

function DinamikYapi ({ match }){
    console.log(match)
 return (
    <div>
      <h2>User: {match.params.user}</h2>
    </div>
  )
};

export default {
    routeProps: {
        path: '/:user',
        component: DinamikYapi
    },
    name: '/:user',
    //icon:<IoMdAnalytics />
}