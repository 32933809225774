import React, { Component } from 'react';
import Swiper from 'react-id-swiper';
import {BrandSC} from './styles'

class Brands extends Component {
  render() {
    return (
          <BrandSC>
            <p>Güvenilir 20 den fazla teknoloji firmasıyla partnerlik</p>
            <Swiper {...params}>


            <img src="https://logos-download.com/wp-content/uploads/2016/12/Avira_logo_logotype-700x228.png" alt="Pineapple"/>
            <img src="https://logos-download.com/wp-content/uploads/2016/04/Dell_logo_logotype_emblem-700x692.png" alt="Pineapple"/>
            <img src="https://logos-download.com/wp-content/uploads/2016/09/HP_Hewlett-Packard_logo-700x700.png" alt="Pineapple"/>
            <img src="https://logos-download.com/wp-content/uploads/2016/02/Microsoft_logo-700x149.png" alt="Pineapple"/>
            <img src="https://i2.wp.com/cosnconference.org/wp-content/uploads/2016/11/fortinet-logo.png?ssl=1" alt="Pineapple"/>
            </Swiper>
          </BrandSC>
    );
  }
}
const params = {
      slidesPerView: 5,
      centeredSlides: true,
      spaceBetween: 30,
      loop: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false
      },
      
    };
export default Brands;
