import React, { Component } from 'react';
import { Link } from "react-router-dom";
import modules from '../../modules';
import Logo from './logo'
import { HeaderSc } from './styles'


class Header extends Component {
  render() {
    return (
      <HeaderSc>
       <Logo/>

        <nav>
          <ul>
            {modules.map(module => (
              <li key={module.name}>
                <Link to={module.routeProps.path}>{module.name}</Link>
              </li>
            ))}

          </ul>
        </nav>
        <div className="Social-Button-Wrapper">
          <Link to="/kariyer">Kariyer</Link>
          <Link to="/turkce">Türkçe</Link>
        </div>
      </HeaderSc>

    );
  }
}

export default Header;
