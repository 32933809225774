import styled from 'styled-components'
export const SliderSC = styled.div`
 width: 100%;
        padding: 35px 100px;
        box-sizing: border-box;
        font-family:"Comfortaa";
        .sol{
          width: 50%;
          float: left;
          h1{
            font-weight: 700;
            font-size: 40px;
            color: #1c2538;
            margin-bottom: 22px;
            margin-top: 60px;
          }
          p{
            font-size: 20px;
            font-family: 'Open Sans', sans-serif;
            img{
              vertical-align: middle;
              margin-right: 8px;
              width: 26px;
            }
            &:last-child{
              font-size: 18px;
              color: #f31b1b;
            }
          }
        }
        .sag{
          width: 50%;
          float: left;
          img{
            width: 100%;
            object-fit: cover;
          }
        }
`