import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap');
  body {
    margin: 0;
    padding: 0;
    font-family: 'Comfortaa','Open Sans';
    display:flex;
    flex-direction:column;
    #root{
      display: flex;
    flex-direction: column;
    }
    .swiper-pagination-bullet-active {
    opacity: 1;
    background: #f31b1b;
  }
    ::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background:#1c2538;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
  }
`;

export default GlobalStyle;