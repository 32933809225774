import styled from 'styled-components'

export const HeaderSc = styled.header`
      width: 100%;
      padding: 35px 100px;
      box-sizing: border-box;
      /* float: left; */
      display:flex;
      flex-direction:row;
      justify-content:space-between;
      nav{
        ul{
          //float: left;
          padding: 0;
          margin: 0 auto;
          display: table;
          li{
            float: left;
            font-size: 13px;
            font-weight: 600;
            list-style: none;
            padding: 10px 20px;
            letter-spacing: 1px;
            color:#f31b1b;
            font-family: "Comfortaa","Calibre", "Helvetica Neue", Helvetica, Arial, sans-serif;
            &:hover{
              border-color: transparent;
              background-color: transparent;
              background-color: #F0F2F5;
            }
            a{
              text-decoration: none;
              color:#f31b1b;
            }
          }
        }

      }
      .Social-Button-Wrapper{
        font-family: "Comfortaa","Calibre", "Helvetica Neue", Helvetica, Arial, sans-serif;
        a{
          float: right;
          text-decoration: none;
          font-size: 13px;
          font-weight: 600;
          padding: 10px 20px;
          letter-spacing: 1px;
          border: 1px solid #f31b1b;
          background-color: #f31b1b;
          color: white;
          margin: 0px 5px;
          &:last-child{
            border: 1px solid #f31b1b;
            background-color: transparent;
            color:#f31b1b;
            &:hover{
              background-color: #F0F2F5;
              /* background-color: #f31b1b; */
              color: #f31b1b;
            }
          }
          &:first-child{

            &:hover{
              background-color: #F0F2F5;
              color: #f31b1b;
            }
          }
        }
      }
`