import React from 'react';
import { ServicesSC, Itemwrapper,Item } from './styles'
import { IoCodeSlashOutline,IoGitNetworkOutline } from 'react-icons/io5';
import { CgServer } from 'react-icons/cg';
import { RiPagesLine } from 'react-icons/ri';
export default () => {
    return <ServicesSC>
        <p>Öne Çıkan Hizmetlerimiz</p>
        <Itemwrapper>
            <Item color="purple">
                <div className="icon">
                    <IoCodeSlashOutline/>
                </div>
                <div className="content">
                    <h4>Web Tasarım ve Programlama</h4>
                    <article>
                        Yeni ve özgün tasarım anlayışı ile ihtiyaca uygun web siteleri tasarlıyoruz.
                    </article>
                </div>
            </Item>
            <Item color="orange">
                <div className="icon">
                    <CgServer/>
                </div>
                <div className="content">
                    <h4>Sunucu Kurulumları</h4>
                    <article>
                        İşletmeye uygun sunucu özelleştirme
                    </article>
                </div>
            </Item>
            <Item color="#28a745">
                <div className="icon">
                    <RiPagesLine/>
                </div>
                <div className="content">
                    <h4>Hosting Hizmeti</h4>
                    <article>
                        Web yazılımınızın türüne göre hosting hizmeti sağlıyoruz.
                    </article>
                </div>
            </Item>
            <Item color="#e83e8c">
                <div className="icon">
                    <IoGitNetworkOutline/>
                </div>
                <div className="content">
                    <h4>Network Ölçeklendirme</h4>
                    <article>
                       Network yapınızı bir sonraki seviyeye çıkarıyoruz.
                    </article>
                </div>
            </Item>
        </Itemwrapper>
    </ServicesSC>
}