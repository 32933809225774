import styled from 'styled-components'
export const BrandSC = styled.div`
    width: 100%;
        padding: 45px 100px;
        height: auto;
        box-sizing: border-box;
        background: #f7f8f9;
        p{
          padding-bottom: 10px;
          text-align: center;
          font-size: 30px;
          font-weight: lighter;
          color:#1c2538;
          margin-top:0;
        }
        img {
          margin: 0px 30px;
          //width: 150px;
          height: 50px;
          object-fit: contain;
          -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
          filter: grayscale(100%);
        }
`