import styled from 'styled-components'

export const FooterSC = styled.footer`
    width:100%;
    background-color:black;
    padding: 35px 100px;
    box-sizing: border-box;
    color:white;
    display:flex;
    flex-direction:column;
    .top{
        display:flex;
        flex-direction:row;
        justify-content:flex-start;
        .links{
            display:flex;
            flex-direction:row;
            justify-content:space-between;
            margin-left:100px;
            ul{
                margin:0;
                padding-left:100px;
                li{
                    list-style:none;
                    padding:5px;
                    font-weight:100;
                    color:rgba(255,255,255,0.5);
                    &:first-child{
                        font-weight:bold;
                        color:white;
                    }
                }
            }
        }
    }
    .bottom{
        text-align:center;
        font-size:14px;
        border-top:1px solid rgba(255,255,255,0.1);
        padding-top:2em;
        margin-top:2em;
        box-sizing:border-box;
    }
`