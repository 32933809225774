import React, { Component } from 'react';





class Iletisim extends Component {
  render() {
    return (
      <div>
        İletisim
      </div>
    );
  }
}
export default {
  routeProps: {
      path: '/iletisim',
      component: Iletisim
  },
  name: 'İletişim',
  //icon:<IoMdAnalytics />
}
