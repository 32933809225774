import styled from 'styled-components'
export const ServicesSC = styled.div`
    width: 100%;
    padding: 45px 100px;
    height: auto;
    box-sizing: border-box;
    display:flex;
    flex-direction:column;
    p{
        font-size:30px;
        font-weight:bold;
        color:#1c2538;
        text-align:center;
    }
`
export const Itemwrapper = styled.div`
display:flex;
flex-direction:row;
flex-wrap:wrap;
justify-content:center;
`
export const Item = styled.div`
    flex-basis:40%;
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    border-radius: 5px;
    padding:30px;
    margin:15px;
    background: white;
    box-shadow: 0 1px 2px rgba(0,0,0,0.02), 
                0 2px 4px rgba(0,0,0,0.02), 
                0 4px 8px rgba(0,0,0,0.02), 
                0 8px 16px rgba(0,0,0,0.02),
                0 16px 32px rgba(0,0,0,0.02), 
                0 32px 64px rgba(0,0,0,0.02);
    &:hover{
        background-color:#f31b1c;
        transition:500ms;
        .content{
            h4,article{
                color:white;
            }
        }
        .icon{
            svg{
                color:white;
            }
        }
    }
    .icon{
        width:100px;
        height:50px;
        svg{
            width:100%;
            height:100%;
            color:${props => props.color || 'black'};
        }
        }
        
    }
    .content{
        padding:0px 20px;
        h4{
            margin:0;
            padding:0;
            font-weight:bold;
            margin-bottom:10px;
            font-size:18px;
        }
        article{
            font-weight:400;
            line-height: 1.5em;
        }
    }
`