import React, { Component } from 'react';
import {SectionSC} from './styles'
import Slider from '../../components/slider';
import Brands from '../../components/brands';
import Services from '../../components/services'
import Istatistik from '../../components/istatistik'
class Home extends Component {
  render() {
    return (
      <SectionSC>
        <Slider/>
        <Brands/>
        <Services/>
        <Istatistik/>
      </SectionSC>
    );
  }
}

export default {
  routeProps: {
      path: '/',
      component: Home,
      exact: true
  },
  name: 'Ana Sayfa',
  //icon:<IoMdAnalytics />
}
