import React, { useState } from 'react';

import modules from '../modules';
import { Switch, Route, } from "react-router-dom";
export default function Routes() {
    //const [apiUrl] = useState("http://api.unimed.com.tr/")
    return (
       
            modules.map(module => (
                <Route {...module.routeProps} key={module.name}>
                    <module.routeProps.component /*apiUrl={apiUrl}*/ />
                </Route>
            ))
      
    )
}