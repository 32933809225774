import React,{useState} from 'react';
import { IstatistikSC } from './styles'
export default () => {
    const [exp,setExp] = useState(new Date().getFullYear() - 1993)
    return <IstatistikSC>
        <div className="item">
            <span>{exp}</span>
            <article>Yıllık Tecrübe</article>
        </div>
        <div className="item">
            <span>50+</span>
            <article>Anlaşmalı İşletme</article>
        </div>
        <div className="item">
            <span>62+</span>
            <article>Hosting hizmeti verilen işletme</article>
        </div>
        <div className="item">
            <span>21+</span>
            <article>Web Tasarım Projesi</article>
        </div>
    </IstatistikSC>
}