import React, { Component } from 'react';





class Hakkimizda extends Component {
  render() {
    return (
      <div>
        Hakkımızda
      </div>
    );
  }
}

export default {
  routeProps: {
      path: '/hakkimizda',
      component: Hakkimizda
  },
  name: 'Hakkımızda',
  //icon:<IoMdAnalytics />
}

