import Hakkimizda from './hakkimizda';
import Hizmetlerimiz from './hizmetlerimiz';
import Iletisim from './iletisim';
import AnaSayfa from './anasayfa';
import DinamikYapi from './dinamikYapi'


export default [
    AnaSayfa,
    Hakkimizda,
    Hizmetlerimiz,
    Iletisim,
    DinamikYapi
];