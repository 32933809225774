import React, { Component } from 'react';

import Footer from '../../components/footer';


class Home extends Component {
  render() {
    return <Footer/>
  }
}

export default Home;
