import styled from 'styled-components'

export const LogoSc = styled.div`
        img{
          width: 35px;
          //vertical-align: middle;
          float: left;
          margin-right: 15px;
        }
        span{
          font-size: 20px;
          font-weight: 600;
          float: left;
          padding-top: 5px;
          font-family: "Comfortaa","Calibre", "Helvetica Neue", Helvetica, Arial, sans-serif;
          -webkit-font-smoothing: antialiased;
          color:white;
        }
`