import styled from 'styled-components'
export const IstatistikSC = styled.div`
         width: 100%;
        padding: 45px 100px;
        box-sizing: border-box;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1001%26quot%3b)' fill='none'%3e%3crect width='1440' height='560' x='0' y='0' fill='rgba(243%2c 27%2c 28%2c 1)'%3e%3c/rect%3e%3cpath d='M0%2c352.271C81.264%2c350.141%2c173.292%2c419.596%2c238.227%2c370.689C304.412%2c320.84%2c268.641%2c212.708%2c291.167%2c132.971C309.289%2c68.823%2c347.792%2c14.557%2c357.467%2c-51.396C370.159%2c-137.911%2c399.926%2c-232.844%2c355.915%2c-308.402C310.93%2c-385.632%2c218.776%2c-433.344%2c129.839%2c-442.19C45.25%2c-450.603%2c-29.8%2c-395.646%2c-103.987%2c-354.146C-164.015%2c-320.567%2c-216.307%2c-278.367%2c-259.176%2c-224.578C-300.603%2c-172.598%2c-323.917%2c-112.329%2c-346.525%2c-49.823C-374.744%2c28.195%2c-426.443%2c105.383%2c-407.759%2c186.217C-388.237%2c270.676%2c-326.676%2c350.699%2c-246.392%2c383.393C-168.137%2c415.261%2c-84.466%2c354.485%2c0%2c352.271' fill='%23cd0b0c'%3e%3c/path%3e%3cpath d='M1440 1038.8809999999999C1530.902 1038.194 1550.336 899.153 1622.097 843.3489999999999 1684.31 794.971 1781.254 800.649 1827.973 737.181 1877.375 670.069 1880.74 580.427 1876.914 497.181 1872.796 407.587 1866.734 308.80899999999997 1805.281 243.48200000000003 1744.299 178.656 1643.102 184.18400000000003 1557.992 158.156 1471.718 131.772 1390.767 74.22300000000001 1301.856 89.524 1209.609 105.399 1120.859 161.454 1072.641 241.68200000000002 1026.481 318.486 1057.23 414.81 1052.576 504.297 1048.417 584.267 1005.775 670.79 1046.747 739.593 1087.6399999999999 808.264 1192.524 797.71 1256.125 846.114 1328.248 901.004 1349.368 1039.566 1440 1038.8809999999999' fill='%23f64e4f'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1001'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
        color:white;
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        .item{
            display:flex;
            flex-direction:column;
            span{
            font-size:60px;
            font-weight:bold;
            text-align:center;
            /* text-shadow:0px 0px 10px black; */
        }
        article{
            text-align:center;
        }
        }
        
        `
