import React, { Fragment, Component } from 'react';
import { BrowserRouter as Router, Switch } from "react-router-dom";
import Header from './components/header';
// import './App.css';
import Routes from './routes'
import Footer from './layouts/footer'
class App extends Component {
  render() {
    return (

      <Router>
        <Fragment>
          <Header />
          <Switch>
            <Routes />
            {/* <Route exact path="/" component={Home} />
            <Route path="/hakkimizda" component={Hakkimizda} />
            <Route path="/hizmetlerimiz" component={Hizmetlerimiz} />
            <Route path="/iletisim" component={Iletisim} />
            <Route path="/:user" component={User} /> */}
          </Switch>
          <Footer />
        </Fragment>
      </Router>

    );
  }
}
// const User = ({ match }) => (
//   <div>
//     <h2>User: {match.params.user}</h2>
//   </div>
// );
export default App;
