import React, { Component } from 'react';





class Hizmetlerimiz extends Component {
  render() {
    return (
      <div>
        Hizmetlerimizi
      </div>
    );
  }
}
export default {
  routeProps: {
      path: '/hizmetlerimiz',
      component: Hizmetlerimiz
  },
  name: 'Hizmetlerimiz',
  //icon:<IoMdAnalytics />
}
